import { get } from "lodash";
import { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrderResponseType, validateQuote } from "_api";
import { useRecoilState } from "recoil";
import { atomToken, atomUserRole } from "_atoms";
import { toast } from "react-toastify";
import ToggleButton from "../ToggleButton/ToggleButton";
import "./table.scss";

export type orderTableProps = {
  orders: OrderResponseType[];
  userCanToggle: boolean;
  setClientInfoModal: (order: OrderResponseType | null) => void;
  setCommentaryModal: (quote: OrderResponseType | null) => void;
  onSelectedCUNOChange: (newCUNO: string) => void;
  setPointOfSaleModal: (order: OrderResponseType | null) => void;

};

const OrderTable: FunctionComponent<orderTableProps> = ({
  setClientInfoModal,
  orders,
  setCommentaryModal,
  userCanToggle,
  setPointOfSaleModal,
  onSelectedCUNOChange,
}) => {
  const { t } = useTranslation();
  const [token] = useRecoilState(atomToken);
  const [userRole] = useRecoilState(atomUserRole);
  const isUserTrigano =  userRole?.name === "User-trigano";

  const handleToggle = async (order: OrderResponseType) => {
      try {
        const response = await validateQuote(order.CUNO, order.orderNumber);
        if (response.status === 200) {
          toast.success(t("order_validated"));
        } else {
          toast.error(t("order_validated_failed"));
        }
      } catch (error) {
        toast.error(t("order_validated_failed"));
      }

  };

  const handleChangePointOfSale = (orderNumber: string) => {
    const selectedOrder = orders.find(order => order.orderNumber === orderNumber);  
    if (selectedOrder) {
        onSelectedCUNOChange(selectedOrder.CUNO); 
        setPointOfSaleModal(selectedOrder);
    } else {
        console.error('Order not found');
    }
  };

  const downloadPDF = (pdfURL: string, pdfName: string) => {
    fetch(pdfURL, {
      headers: new Headers({
        Access_token: token || "",
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = pdfName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch(() => {
        toast.error(t("toast_get_data_error"));
      });
  };

  const [sortConfig, setSortConfig] = useState<{
    key: string | null;
    direction: string;
  }>({
    key: null,
    direction: "ascending",
  });

  const sortedOrders = useMemo(() => {
    const sortableItems = [...orders];
    if (sortConfig.key) {
      const key = sortConfig.key;
      sortableItems.sort((a, b) => {
        let aValue;
        let bValue;

        if (key === "acknowledgment") {
          aValue = a.FLG_PDF_AR ? 1 : 0;
          bValue = b.FLG_PDF_AR ? 1 : 0;
        } else if (key === "invoice") {
          aValue = a.FLG_PDF_FACTURE ? 1 : 0;
          bValue = b.FLG_PDF_FACTURE ? 1 : 0;
        } else {
          aValue = get(a, key);
          bValue = get(b, key);

          if (typeof aValue === "string") aValue = aValue.toLowerCase();
          if (typeof bValue === "string") bValue = bValue.toLowerCase();
        }

        const aIsEmpty =
          aValue === null || aValue === undefined || aValue === "";
        const bIsEmpty =
          bValue === null || bValue === undefined || bValue === "";

        if (aIsEmpty && bIsEmpty) return 0;
        if (aIsEmpty) return 1;
        if (bIsEmpty) return -1;

        if (aValue < bValue)
          return sortConfig.direction === "ascending" ? -1 : 1;
        if (aValue > bValue)
          return sortConfig.direction === "ascending" ? 1 : -1;
        return 0;
      });
    }
    return sortableItems;
  }, [orders, sortConfig]);

  const requestSort = (key: string) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="page-container">
      <div className="table">
        <div className="table__header">
          <div className="table__sticky">
            <div className="table__group">
              <div className="table__title validation">{t("validation")}</div>
              <div
                className={`table__title order-number ${
                  sortConfig.key === "orderNumber"
                    ? sortConfig.direction === "ascending"
                      ? "table__title--asc"
                      : "table__title--desc"
                    : ""
                } table__title--sortable`}
                onClick={() => requestSort("orderNumber")}
              >
                {t("order_number")}
              </div>
            </div>
          </div>
          <div className="table__group">
            <div
              className={`table__title year ${
                sortConfig.key === "year"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => requestSort("year")}
            >
              {t("year")}
            </div>
            <div className="table__title order-status ">{t("status")}</div>
            <div
              className={`table__title dealer-name ${
                sortConfig.key === "dealerName"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => requestSort("dealerName")}
            >
              {t("dealer_name")}
            </div>
            <div className="table__title dealer-location">
              {t("dealer_name_location_short")}
            </div>
            <div
              className={`table__title brand ${
                sortConfig.key === "brand"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => requestSort("brand")}
            >
              {t("brand")}
            </div>
            <div
              className={`table__title model ${
                sortConfig.key === "model"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => requestSort("model")}
            >
              {t("model")}
            </div>
            <div
              className={`table__title acknowledgment ${
                sortConfig.key === "acknowledgment"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => requestSort("acknowledgment")}
            >
              {t("acknowledgment")}
            </div>
            <div
              className={`table__title info-dealer ${
                sortConfig.key === "infoDealer"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => requestSort("infoDealer")}
            >
              {t("info_dealer")}
            </div>
            <div className="table__title info-client">{t("info_client")}</div>
            <div
              className={`table__title production-date  ${
                sortConfig.key === "productionDate"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => requestSort("productionDate")}
            >
              {t("production_date")}
            </div>
            <div
              className={`table__title invoice-number ${
                sortConfig.key === "invoiceNumber"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => requestSort("invoiceNumber")}
            >
              {t("invoice_number")}
            </div>
            <div
              className={`table__title invoice ${
                sortConfig.key === "invoice"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => requestSort("invoice")}
            >
              {t("invoice")}
            </div>
            <div
              className={`table__title invoice-date ${
                sortConfig.key === "invoiceDate"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => requestSort("invoiceDate")}
            >
              {t("invoice_date")}
            </div>
            <div className="table__title serie-number ">
              {t("serie_number")}
            </div>
            <div
              className={`table__title stock ${
                sortConfig.key === "stock"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => requestSort("stock")}
            >
              {t("stock")}
            </div>
            <div
              className={`table__title collection-date ${
                sortConfig.key === "collectionDate"
                  ? sortConfig.direction === "ascending"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : ""
              } table__title--sortable`}
              onClick={() => requestSort("collectionDate")}
            >
              {t("collection_date")}
            </div>
          </div>
        </div>
        {sortedOrders.map((order) => {
          return (
            <div className="table__line" key={order.orderNumber}>
              <div className="table__sticky">
                <div className="table__group">
                  <div className="table__item validation">
                  {order.FLG_CONFIRMATION_NEEDED && (
                      <ToggleButton
                        toggled={order.validation}
                        disabled={!userCanToggle}
                        handlechange={() => handleToggle(order)}
                      />
                    )}

                  </div>
                  <div className="table__item order-number">
                    {order.orderNumber}
                  </div>
                </div>
              </div>
              <div className="table__group">
                <div className="table__item year">{order.year}</div>
                <div className="table__item order-status">{order.status}</div>
                <div className="table__item dealer-name">
                  {order.dealerName}
                </div>
                <div className="table__item dealer-location">
                    {order.point_of_sale && order.point_of_sale.name && (
                      <>
                        {order.point_of_sale.name}
                        <span
                          className="edit"
                          onClick={() => handleChangePointOfSale(order.orderNumber)}
                        ></span>
                      </>
                    )}
                    {(!order.point_of_sale || !order.point_of_sale.name) && (
                      <span
                        className="edit"
                        onClick={() => handleChangePointOfSale(order.orderNumber)}
                      ></span>
                    )}
                  </div>
                <div className="table__item brand">{order.brand}</div>
                <div className="table__item model">{order.model}</div>
                <div className="table__item acknowledgment">
                  {order.acknowledgment}
                  {order.FLG_PDF_AR && (
                    <span
                      className="pdf"
                      onClick={() =>
                        downloadPDF(
                          order.URL_PDF_AR,
                          "AR-" + order.orderNumber + ".pdf"
                        )
                      }
                    ></span>
                  )}
                </div>
                <div className="table__item info-dealer">
                  {order.infoDealer}
                  <span
                    className="edit"
                    onClick={() => setCommentaryModal(order)}
                  ></span>
                </div>
                <div className="table__item info-client">
                  {`${order.infoClient.firstName ?? ""} ${
                    order.infoClient.lastName ?? ""
                  }`}
                  <span 
                    className="edit"
                    onClick={() => setClientInfoModal(order)}
                    hidden={isUserTrigano}
                  ></span>
                </div>
                <div className="table__item production-date">
                  {new Date(order.productionDate).toLocaleDateString()}
                </div>
                <div className="table__item invoice-number">
                  {order.invoiceNumber}
                </div>
                <div className="table__item invoice">
                  {order.invoice}
                  {order.FLG_PDF_FACTURE && (
                    <span
                      className="pdf"
                      onClick={() =>
                        downloadPDF(
                          order.URL_PDF_FACTURE,
                          "Facture-" + order.orderNumber + ".pdf"
                        )
                      }
                    ></span>
                  )}
                </div>
                <div className="table__item invoice-date">
                  {new Date(order.invoiceDate).toLocaleDateString()}
                </div>
                <div className="table__item serie-number">
                  {order.seriesNumber}
                </div>
                <div className="table__item stock">{order.stock}</div>
                <div className="table__item collection-date">
                  {new Date(order.collectionDate).toLocaleDateString()}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrderTable;
