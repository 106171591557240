import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../../assets/img/trigano-login.svg";
import "./footer.scss";
import { NavLink } from "react-router-dom";

const Footer: FunctionComponent = () => {
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;

  console.log(currentLanguage);

  const getFooterLinksContent = () => {
    return (
      <div className="footer__links">
        <div className="footer__links-column">
          <a
            href="https://www.youtube.com/@TRIGANOPRO"
            target="_blank"
            className="footer__link"
          >
            {/*t("footer_welcome")*/}
            Tuto video on Youtube
            <i className="icon-youtube" />
          </a>
        </div>
        <div className="footer__links-column">
          {currentLanguage === "fr" ||
          currentLanguage === "de" ||
          currentLanguage === "it" ||
          currentLanguage === "es" ? (
            <a
              href="https://www.challenger-camping-cars.fr/"
              target="_blank"
              className="footer__link"
            >
              Challenger
              <i className="icon-arrow-top-right" />
            </a>
          ) : null}
          {currentLanguage === "fr" ||
          currentLanguage === "de" ||
          currentLanguage === "en" ||
          currentLanguage === "it" ? (
            <a
              href="https://www.chausson-camping-cars.fr/"
              target="_blank"
              className="footer__link"
            >
              Chausson <i className="icon-arrow-top-right" />
            </a>
          ) : null}

          {currentLanguage === "fr" ||
          currentLanguage === "de" ||
          currentLanguage === "it" ||
          currentLanguage === "es" ? (
            <a
              href="https://www.caravelair-caravanes.fr/"
              target="_blank"
              className="footer__link"
            >
              Caravelair <i className="icon-arrow-top-right" />
            </a>
          ) : null}
        </div>
        <div className="footer__links-column">
          {currentLanguage === "fr" ||
          currentLanguage === "de" ||
          currentLanguage === "es" ? (
            <a
              href="https://www.sterckeman-caravanes.fr/"
              target="_blank"
              className="footer__link"
            >
              Sterckeman <i className="icon-arrow-top-right" />
            </a>
          ) : null}

          {currentLanguage === "fr" ? (
            <a
              href="https://www.caravane-rubis.com/"
              target="_blank"
              className="footer__link"
            >
              Rubis <i className="icon-arrow-top-right" />
            </a>
          ) : null}
          {currentLanguage === "fr" ||
          currentLanguage === "de" ||
          currentLanguage === "en" ||
          currentLanguage === "it" ||
          currentLanguage === "es" ? (
            <a
              href="https://www.mini-freestyle.com/"
              target="_blank"
              className="footer__link"
            >
              Mini Freestyle <i className="icon-arrow-top-right" />
            </a>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <footer className="footer">
      <div className="footer__content-wrapper">
        {getFooterLinksContent()}
        <div className="footer__logo-container">
          <Logo className="footer__logo" />
        </div>
        <div className="footer__legal-infos-container">
          <span className="footer__legal-info">
            © Trigano {new Date().getFullYear()}
          </span>
          <div>
            <span className="footer__legal-info">
              <NavLink to="/legal-infos">{t("footer_legal_infos")}</NavLink>
            </span>
            <span className="footer__legal-info">
              <NavLink to="/privacy-policy">
                {t("footer_privacy_policy")}
              </NavLink>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
